<template>
  <div class="pur-rec">
    <div class="center-title">我的学分</div>
    <div class="title">学分总分：<span style="color:red">{{ orderList.total_credit }}</span></div>
    <div class="title">详细记录：</div>
    <ul class="my-table">
      <li class="table-th">
        <span class="table-td" style="width: 182px">标题</span>
        <span class="table-td" style="width: 182px">学分数</span>
        <span class="table-td" style="width: 182px">获取时间</span>
      </li>
      <li class="table-tr" v-for="item in orderList.record.data" :key="item.id">
        <span class="table-td" style="width: 182px">{{item.title}}</span>
        <span class="table-td" style="width: 182px">{{item.number}}</span>
        <span class="table-td" style="width: 182px">{{item.created_at}}</span>
      </li> 
    </ul>
    <p style="text-align: center;margin-top: 20px">
      <el-pagination
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :page-size="5"
        :total="total">
      </el-pagination>
    </p>
  </div>
</template>
<script>
export default {
  name: 'PurchaseRecords',
  data(){
    return {
      orderList: '',  //记录
      listQuery: {
        page: 1,
        pre_page: 5
      },
      total: 0,
    }
  },
  mounted(){
    this.getOrderList(); //获取记录
  },
  methods: {
    handleCurrentChange(val) {
      this.listQuery.page = val
      this.getOrderList()
    },
    //获取记录
    getOrderList(){
      this.$axios.post(`/v1/personal/creditRecord`, {
        ...this.listQuery
      },
      {useLog: true}).then(res => {
        this.orderList = res.data
        this.total = res.data.record.total
      })
    }
  }
}
</script>

<style scoped lang="scss">
.my-table{
  width: 100%;
  .table-th,
  .table-tr{
    height: 56px;
  }
  .table-td{
    font-size: 12px;
  }
}
.ope{
  font-size: 12px;
  color: #254ED4
}
.link{
  color: #254ED4;
}
.center-title {
  margin-bottom: 10px;
}
.title {
  font-size: 16px;
  margin: 10px;
  color: #999;
  font-weight: 500;
}
.span {
  display: inline-block;
  font-size: 16px;
  font-weight: 800;
  padding-left: 20px;
  color: #3053db;
}
</style>
